import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import SimpleReactLightbox from "simple-react-lightbox";
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import { Link as SmoothScroll } from 'react-scroll'

import Layout from '../components/layout';
import PageHero from '../components/pageHero/PageHero';
import Section from '../components/section/Section';
import Features from "../components/equipment/Features";
import Gallery from "../components/gallery/Gallery";
import House from "../components/house/House";
import RoomsGallery from "../components/roomsGallery/roomsGallery";
import CalendarSection from '../components/calendarSection/CalendarSection';

import "../components/equipment/scss/index.scss"

const singleHouse = ({data: {houseData, houses: {edges: houses}}, pageContext }) => {
    const houseContent = houseData.content.split(`<!--more-->`)
    const houseId = pageContext.slug.charAt(pageContext.slug.length - 1)

    const settings = {
        centerMode: true,
        centerPadding: 0,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        waitForAnimate: false,
        cssEase: 'ease-out',
        arrows: true
    };

    return (
        <Layout>
            <PageHero
             houses 
             header={houseData.title + ' ' + houseData.acf.space + 'm²'} 
             background={houseData.featured_media ? houseData.featured_media.localFile.publicURL : ''} 
             icon={houseData.acf.icon.localFile.publicURL}>
              <p className="hero-excerpt">{ ReactHtmlParser(houseData.acf.excerpt) }</p>
              <div className='flex-grow-1 d-flex align-items-center'>
              <SmoothScroll to="calendar" className="btn btn-primary mt-3 text-uppercase fw-bold" smooth={true} offset={-100} duration={500}>
                Rezerwuj
              </SmoothScroll>
              </div>
            </PageHero>
            <Section header="Wstęp" hideHeader>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-9 text-center fs-s3 mb-6 mb-lg-10">
                        {houseContent ?
                            <>
                                <div className="h3 fw-light d-none d-lg-inline">{ ReactHtmlParser(houseContent[0]) }</div>
                                <div className="h4 fw-light d-inline d-lg-none">{ ReactHtmlParser(houseContent[0]) }</div>
                            </>
                            :
                            ''
                        }
                        <SmoothScroll to="calendar" smooth={true} offset={-100} duration={500} className="btn btn-primary mt-4 fw-bold text-uppercase">Rezerwuj</SmoothScroll>
                    </div>
                </div>
            </Section>
            <Section id="wyposazenie" header="Wyposażenie" hideHeader className="section-dark">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-9">
                        <p className="h4 text-center mb-5 fw-light">{ ReactHtmlParser(houseContent[1]) }</p>
                    </div>
                    <div className="col-12">
                        <RoomsGallery houseData={houseData}/>
                    </div>
                </div>
                <div className="row mt-4 mt-lg-9 pb-lg-7 justify-content-center">
                    {houseData.acf.availability === true ?
                        <>
                            <div className="equipment-borders col-12 col-lg-8 mb-3 mb-lg-0">
                                <Features wrap title="Wyposażenie standardowe" equipment={houseData.acf.equipment_standard}/>
                            </div>
                            <div className="equipment-borders col-12 col-lg-4 mt-20 mt-lg-0">
                                <Features wrap title="Wyposażenie opcjonalne" equipment={houseData.acf.equipment_optional}/>
                            </div>
                        </>
                        :
                        <div className="equipment-justify col-12 col-lg-8 d-flex align-items-center">
                                <h3 className="equipment-justify-color-big text-center">
                                    <span className="equipment-justify-color-small">Zapraszamy Gości od:</span><br/>
                                    {houseData.acf.availability_date}
                                </h3>
                                <p className="equipment-justify-small d-none d-lg-block">Sprawdź inne domki</p>
                        </div>
                    }
                </div>
            </Section>

            <CalendarSection houseId={houseId} houseName={houseData.title + ' ' + houseData.acf.space + 'm²'}/>

            {houseData.acf.gallery ?
            <Section header={houseData.acf.gallery_title}>
                <SimpleReactLightbox>
                    <Gallery photos={houseData.acf.gallery}/>
                </SimpleReactLightbox>
                <div className="text-center fs-s3 mt-4 mt-lg-7 mb-lg-10">
                    {houseData.acf.availability ?
                        <SmoothScroll to="calendar" smooth={true} offset={-100} duration={500} className="btn btn-primary mt-4 fw-bold text-uppercase">Rezerwuj</SmoothScroll>
                        :
                        ''
                    }
                </div>
            </Section>
                :
                ""
            }

            <Section header="Potrzebujesz czegoś innego?">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center d-flex align-items-center flex-column d-none d-lg-block">
                        <div className="d-flex flex-row justify-content-center">
                            {houses.map((houseItem, i) => {
                                return (
                                    <Link key={i} className="text-decoration-none d-flex flex-row justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                        <House className="m-2" key={i} houseData={houseItem} />
                                    </Link>
                                )
                            })}
                        </div>
                        <p className="text-center mt-4 mb-5 h4 fw-light">
                          Chcemy dzielić się widokami i klimatem tego miejsca. Powoli uruchamiamy kolejne domki i zapraszamy wszystkich, którzy cenią wypoczynek blisko natury, ale bez rezygnacji z wygody. Aby dowiedzieć się więcej - kliknij poniżej.
                        </p>
                        <Link to="/znizki-ciekawostki-terminy/" className="btn btn-outline-primary mb-8 fw-bold">Tak, chcę dostać info</Link>
                    </div>

                    <div className="col col-lg-8 text-center d-flex align-items-center flex-column d-block d-lg-none">
                        <div className="w-75 d-flex flex-column align-content-center justify-content-center mb-6">
                            <Slider {...settings}>
                                {houses.map((houseItem, i) => {
                                    return (
                                        <Link key={i} className="text-decoration-none d-flex justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                            <House className="m-2" key={i} houseData={houseItem} />
                                        </Link>
                                    )
                                })}
                            </Slider>
                        </div>
                        <p className="text-center mt-4 mb-5 h4 fw-light">
                          Chcemy dzielić się widokami i klimatem tego miejsca. Powoli uruchamiamy kolejne domki i zapraszamy wszystkich, którzy cenią wypoczynek blisko natury, ale bez rezygnacji z wygody. Aby dowiedzieć się więcej - kliknij poniżej.
                        </p>
                        <Link to='/znizki-ciekawostki-terminy/' className="btn btn-outline-primary mb-8 fw-bold">Tak, chcę dostać info</Link>
                    </div>


                </div>
            </Section>
        </Layout>
    )
}

singleHouse.propTypes = {
    data: PropTypes.object.isRequired
}

export default singleHouse;

export const pageQuery = graphql`
    query ($wordpressId: Int!) {
      houseData: wordpressWpHouses(wordpress_id: {eq: $wordpressId}) {
        title
        featured_media {
          localFile {
            publicURL
          }
        }
        acf {
          availability
          availability_date
          availability_info
          excerpt
          people
          space
          equipment_optional {
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          equipment_standard {
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          rooms {
            title
            icon {
              localFile {
                publicURL
              }
            }
            description
          }
          icon {
            localFile {
              publicURL
            }
          }
          gallery_title
          gallery {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1280) {
                  aspectRatio
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                original {
                  src
                }
              }
            }
          }
        }
        content
      }
      houses: allWordpressWpHouses(sort: {fields: wordpress_id, order: ASC}, filter: {wordpress_id: {ne: $wordpressId}}) {
        edges {
          node {
            acf {
              people
              space
              icon {
                localFile {
                  publicURL
                }
              }
            }
            slug
            title
            wordpress_id
          }
        }
      }
    }
`;