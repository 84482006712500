import React, { useState, useEffect } from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import "./scss/index.scss"
import scrollTo from "gatsby-plugin-smoothscroll"

const CalendarSection = ({ houseId, houseName }) => {
  const CALENDAR_UPDATE = "10 minut"
  const MIN_STAY = 4
  const GET_ENDPOINT = `https://szczurze-wzgorze.4beta.pl/wp-json/api/calendar/${houseId}`
  const POST_ENDPOINT =
    "https://szczurze-wzgorze.4beta.pl/wp-json/api/add_event"

  const [selectionError, setSelectionError] = useState(null)
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [calendar, setCalendar] = useState({
    data: [],
    blockedDateRanges: [],
    error: false,
    loading: true,
  })
  const [clientData, setClientData] = useState({
    email: "",
    name: "",
    phone: "",
  })
  const [formState, setFormState] = useState({
    loading: false,
    success: false,
    pushed: false,
    error: false,
  })

  useEffect(() => {
    fetch(GET_ENDPOINT)
      .then(response => response.json())
      .then(data => {
        setCalendar(e => ({ ...e, data: data, loading: false }))
        const dateRanges = data.map(booking => ({
          startDate: new Date(
            new Date(booking.date_start).setHours(0, 0, 0, 0)
          ),
          endDate: new Date(new Date(booking.date_end).setHours(0, 0, 0, 0)),
        }))
        setCalendar(e => ({ ...e, blockedDateRanges: dateRanges }))
      })
      .catch(() => {
        setCalendar(e => ({ ...e, error: true }))
      })
  }, [GET_ENDPOINT, houseId])

  function isDateDisabled(date) {
    return calendar.blockedDateRanges.some(
      range => date >= range.startDate && date <= range.endDate
    )
  }

  function handleDateRangeChange(dateRange) {
    const startDate = dateRange[0]
    const endDate = dateRange[1]
    const stayLength = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
    const today = new Date();
    let dates = []

    if (stayLength < MIN_STAY) {
      setSelectedDateRange(null)
      setSelectionError(
        `Minimalna długość pobytu wynosi ${MIN_STAY - 1} doby hotelowe. Wprowadź poprawne daty.`
      )
      return
    }
    setSelectionError(null)

    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      )
    }
    const isAnyDateDisabled = dates.some(date => isDateDisabled(date))
    if (isAnyDateDisabled) {
      setSelectionError(
        "Wybrany zakres dat zawiera niedostępne terminy. Wybierz inny zakres."
      )
      setSelectedDateRange(null)
      return
    }

    if (startDate < today && today < endDate) {
      setSelectionError("Wybrany zakres nie może zawierać dzisiejszej daty.")
      setSelectedDateRange(null)
      return
    }

    setSelectionError(null)
    setSelectedDateRange(dateRange)
  }

  function getTileClassName({ date, view }) {
    if (view === "month" && isDateDisabled(date)) {
      return "react-calendar__tile--disabled"
    }
    return null
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    if (!selectedDateRange) return
    setFormState(e => ({ ...e, loading: true }))
    fetch(POST_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Secret-Key": `${process.env.GATSBY_API_KEY}`,
      },
      body: JSON.stringify({
        house: houseId,
        date_start: selectedDateRange[0],
        date_end: selectedDateRange[1],
        client_email: clientData.email,
        client_name: clientData.name,
        client_phone: clientData.phone,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setFormState(e => ({
          ...e,
          success: data.success,
          loading: false,
          pushed: true,
        }))
        scrollTo("#calendar")
      })
      .catch(error => {
        setFormState(e => ({ ...e, loading: false, error: true }))
      })
  }

  return (
    <section className="container calendar" id="calendar">
      {calendar.data.length > 0 && !formState.pushed ? (
        <>
          <h3 className="calendar__header">Rezerwacja</h3>
          <p className="calendar__name">{houseName}</p>
          <p className="calendar__desc">
            Znajdź wolne terminy w kalendarzu. Wskaż daty wybierając z dni
            wolnych, w których chcesz zarezerwować swój pobyt
          </p>
          <div className="d-flex flex-column align-items-center">
            <div className="legend flex-column flex-md-row gap-2 gap-md-4">
              <LegendItem color="#e9d2d9" text="Dni zajęte" />
              <LegendItem color="transparent" text="Dni wolne" />
              <LegendItem
                color="#d6e2f0"
                text="Wybrane daty Twojej rezerwacji"
              />
            </div>
            <div className="calendar-big d-none d-md-block">
              <Calendar
                tileDisabled={({ date }) => isDateDisabled(date)}
                selectRange={true}
                onChange={handleDateRangeChange}
                showDoubleView={true}
                maxDetail="month"
                minDetail="month"
                nextLabel={arrRight()}
                prevLabel={arrLeft()}
                tileClassName={getTileClassName}
                formatShortWeekday={(locale, date) =>
                  date
                    .toLocaleDateString(locale, { weekday: "short" })
                    .slice(0, 2)
                }
                formatMonthYear={(locale, date) =>
                  date.toLocaleDateString(locale, {
                    month: "short",
                    year: "numeric",
                  })
                }
                minDate={new Date()}
              />
            </div>
            <div className="calendar-small d-block d-md-none">
              <Calendar
                tileDisabled={({ date }) => isDateDisabled(date)}
                selectRange={true}
                onChange={handleDateRangeChange}
                showDoubleView={false}
                maxDetail="month"
                minDetail="month"
                nextLabel={arrRight()}
                prevLabel={arrLeft()}
                tileClassName={getTileClassName}
                minDate={new Date()}
                formatShortWeekday={(locale, date) =>
                  date
                    .toLocaleDateString(locale, { weekday: "short" })
                    .slice(0, 2)
                }
              />
            </div>
            {selectedDateRange ? (
              <div className="confirm-box d-flex flex-column">
                <p className="confirm-box__desc">
                  Po sprawdzeniu dat w kalendarzu powyżej upewnij się, czy data
                  wyjazdu i przyjazdu została poprawnie oznaczona.
                </p>
                <div className="d-flex gap-4 flex-column flex-md-row">
                  <p className="confirm-box__input">
                    {selectedDateRange[0]?.toLocaleDateString() || " "}
                  </p>
                  <p className="confirm-box__input">
                    {selectedDateRange[1]?.toLocaleDateString() || " "}
                  </p>
                </div>
                <form
                  onSubmit={handleFormSubmit}
                  className="d-flex flex-column mt-4 gap-4"
                >
                  <p className="confirm-box__desc">
                    Podaj swoje dane, abyśmy mogli potwierdzić Twoją rezerwację
                  </p>
                  <input
                    type="email"
                    placeholder="Twój adres e-mail *"
                    maxLength="50"
                    value={clientData.email}
                    onChange={e =>
                      setClientData({ ...clientData, email: e.target.value })
                    }
                    className="confirm-box__input w-100"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Imię i nazwisko*"
                    value={clientData.name}
                    maxLength="50"
                    onChange={e =>
                      setClientData({ ...clientData, name: e.target.value })
                    }
                    className="confirm-box__input w-75"
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Numer telefonu*"
                    value={clientData.phone}
                    maxLength="11"
                    onChange={e =>
                      setClientData({ ...clientData, phone: e.target.value })
                    }
                    className="confirm-box__input w-50"
                    required
                  />
                  <label className="confirm-box__rodo">
                    <input type="checkbox" className="me-3" required />
                    Wyrażam zgodę na przetwarzanie moich danych osobowych
                    zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie
                    danych osobowych (Dz.U. z 2014 r. poz. 1182 ze zm.) w
                    zakresie podanym w formularzu, w celu przedstawienia mi
                    oferty. *
                  </label>
                  <p className="confirm-box__alert">
                    UWAGA: o rezerwacji w określonym terminie decyduje kolejność
                    wysłanych rezerwacji. Kalendarz jest aktualizowany co{" "}
                    {CALENDAR_UPDATE}.
                  </p>

                  {!formState.loading && !formState.error ? (
                    <button type="submit" className="btn btn-primary d-inline">
                      Zarezerwuj
                    </button>
                  ) : null}
                  {formState.loading ? (
                    <p className="confirm-box__loading">
                      Trwa rezerwowanie terminu...
                    </p>
                  ) : null}
                  {formState.error ? (
                    <p className="confirm-box__error">
                      Wystąpił błąd podczas rezerwacji. Spróbuj ponownie
                      później.
                    </p>
                  ) : null}
                </form>
              </div>
            ) : null}

            {selectionError ? (
              <p className="calendar__error">{selectionError}</p>
            ) : null}
          </div>
        </>
      ) : null}

      {formState.pushed && !formState.loading && !formState.error ? (
        <div className="container d-flex justify-content-center my-4 calendar_confirmation">
          {formState.success ? (
            <p className="calendar__header calendar__header--pink">
              Dziękujemy za złożenie rezerwacji.
              <br />
              Na podany adres e-mail zostanie wysłana wiadomość zawierająca
              dalsze instrukcje.
            </p>
          ) : (
            <p className="calendar__header calendar__header--error">
              Wystąpił błąd podczas rezerwacji. Spróbuj ponownie później.
            </p>
          )}
        </div>
      ) : null}

      {calendar.loading && !calendar.error ? (
        <div className="container d-flex justify-content-center my-4">
          <p className="fw-bold h3 my-4">Wczytywanie kalendarza...</p>
        </div>
      ) : null}
    </section>
  )
}

const LegendItem = ({ color, text }) => {
  return (
    <div className="d-flex align-items-center legend__item">
      <div
        className="legend__item-color"
        style={{ backgroundColor: color }}
      ></div>
      <p className="legend__item-description">{text}</p>
    </div>
  )
}

const arrRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.97"
    height="19.112"
    viewBox="0 0 10.97 19.112"
  >
    <path
      id="Path_164"
      data-name="Path 164"
      d="M0,0,8.849,8.849,17.7,0"
      transform="translate(0.707 18.405) rotate(-90)"
      fill="none"
      stroke="#000"
      strokeWidth="2"
    />
  </svg>
)

const arrLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.97"
    height="19.112"
    viewBox="0 0 10.97 19.112"
  >
    <path
      id="Path_165"
      data-name="Path 165"
      d="M0,0,8.849,8.849,17.7,0"
      transform="translate(10.263 0.707) rotate(90)"
      fill="none"
      stroke="#000"
      strokeWidth="2"
    />
  </svg>
)

export default CalendarSection
