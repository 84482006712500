import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import './scss/index.scss'

const RoomsGallery = ({houseData}) => {
    return (
        <div className="pl-3 pr-3">
            <div className='rooms-gallery row justify-content-start justify-content-lg-center'>
                {houseData.acf.rooms.map((room, i) => {
                    return (
                        <div className="col-11 col-lg-6 mb-4" key={i}>
                            <div className="bg-white w-100 h-100">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h4 className="rooms-title mt-4 mb-4 text-center fw-lighter">{room.title}</h4>
                                    <img className="rooms-image mb-3" src={room.icon.localFile.publicURL} alt={room.description}/>
                                    <p className="m-3 mb-8 text-center d-none d-lg-inline">{ ReactHtmlParser(room.description) }</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default RoomsGallery;